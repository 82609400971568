import { createRouter, createWebHistory } from "vue-router";
import MainView from "@/views/MainView.vue";
import LoginView from "@/views/LoginView.vue";

const routes = [
  {
    path: "/",
    component: MainView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "process",
        component: () => import("@/views/ProcessView.vue"),
        props: (route) => ({ bt: route.query.bt }),
      },
      {
        path: "/bt/:id",
        name: "view-process",
        beforeEnter: (to, from, next) => {
          next({ name: "process", query: { bt: to.params.id } });
        },
      },
      {
        path: "/dicas",
        name: "tips",
        component: () => import("@/views/TipsView.vue"),
        meta: {
          title: "Dicas de reparação",
        },
      },
      {
        path: "/inventario",
        name: "inventory",
        component: () => import("@/views/InventoryView.vue"),
        meta: {
          title: "Inventário",
        },
      },
      {
        path: "/actividade",
        name: "activity",
        component: () => import("@/views/ActivityView.vue"),
        meta: {
          title: "Actividade recente",
        },
      },
      {
        path: "/processos",
        name: "processes",
        component: () => import("@/views/ProcessList.vue"),
        meta: {
          title: "Processos",
        },
      },
      {
        path: "/processos/mapa",
        name: "processesMap",
        component: () => import("@/views/ProcessMap.vue"),
        meta: {
          title: "Processos > Mapa",
        },
      },
      {
        path: "/processos/samsung",
        name: "processes-samsung",
        component: () => import("@/views/SamsungProcesses.vue"),
      },
      
      {
        path: "/processos/samsung/crr",
        name: "processes-samsung-crr",
        component: () => import("@/views/SamsungCRR.vue"),
      },
      {
        path: "/processos/hisense",
        name: "processes-hisense",
        component: () => import("@/views/HisenseProcesses.vue"),
      },
      {
        path: "/processos/chart",
        name: "processes-chart",
        component: () => import("@/views/ProcessChartView.vue"),
      },
      {
        path: "/toolbox",
        component: () => import("@/views/ToolboxView.vue"),
      },
      {
        path: "/admin",
        name: "admin",
        component: () => import("@/views/AdminView.vue"),
        meta: {
          title: "Área de administração",
        },
      },
      {
        path: "/admin/utilizadores",
        component: () => import("@/views/admin/UserManager.vue"),
      },
      {
        path: "/admin/visits",
        component: () => import("@/views/admin/VisitsList.vue"),
      },
      {
        path: "/admin/pedidos-pecas",
        component: () => import("@/views/admin/PartsRequested.vue"),
      },
      {
        path: "/admin/processos-de-parceiros",
        component: () => import("@/views/admin/PartnerProcessList.vue"),
      },
      {
        path: "/admin/charts/processos",
        component: () => import("@/views/admin/DailyProcess.vue"),
      },
      {
        path: "/acessos",
        name: "acessos",
        component: () => import("@/views/AccessView.vue"),
        meta: {
          title: "Acessos",
        },
      },
      {
        path: "/utilizador",
        name: "utilizador",
        component: () => import("@/views/UserView.vue"),
        meta: {
          title: "Utilizador",
        },
      },
      {
        path: "/utilizador/:id",
        name: "view-user",
        component: () => import("@/views/UserView.vue"),
        meta: {
          title: "Utilizador",
        },
      },
      {
        path: "/agendamentos",
        component: () => import("@/views/AppointmentsView.vue"),
      },
      {
        name: 'agendamentos-pecas',
        path: "/agendamentos/pecas",
        component: () => import("@/views/AppointmentsParts.vue"),
      },
      {
        path: "/visit/:rid",
        component: () => import("@/views/AddVisit.vue"),
      },
      {
        path: "/setlocation/:id",
        component: () => import("@/views/SetLocationView.vue"),
      },
      {
        path: "/search",
        component: () => import("@/views/ProcessSearch.vue"),
      },
      {
        path: "/tickets",
        component: () => import("@/views/Tickets/TicketListView.vue"),
      },
      {
        path: "/tickets/new",
        component: () => import("@/views/Tickets/NewTicketView.vue"),
      },
      {
        path: "/tickets/:id",
        component: () => import("@/views/Tickets/TicketView.vue"),
      },
      {
        path: "/unscheduled",
        component: () => import("@/views/UnscheduledView.vue"),
      },
      {
        path: "/samsung-pending-parts",
        component: () => import("@/views/SamsungParts.vue"),
      },
      {
        path: "/agendamentos-samsung",
        component: () => import("@/views/SamsungAppointments.vue"),
      },
      {
        path: "/admin/sms",
        component: () => import("@/views/MessageReviewPage.vue"),
      },
      {
        path: "/admin/locations",
        component: () => import("@/views/admin/EqLocations.vue"),
      },
      {
        path: "/admin/bot-tickets",
        component: () => import("@/views/admin/BotTickets.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        component: () => import("@/views/NotFound.vue"),
      },
      {
        path: "/todo",
        component: () => import("@/views/ImplementationsView.vue"),
      },
      {
        path: "/loyalties",
        component: () => import("@/views/LoyaltyReports.vue"),
      },
      {
        path: "/loyalties/estatisticas",
        component: () => import("@/views/LoyaltyStats.vue"),
      },
      {
        path: "/abastecimentos",
        component: () => import('@/views/AppointmentPartsLog.vue'),
      },
      {
        name: "agendamentos-pecas-lista",
        path: "/abastecimentos/lista",
        component: () => import("@/views/AppointmentPartsRequests.vue"),
      },
      {
        name: "model-history",
        path: "/model-history/:model",
        component: () => import("@/views/ModelHistory.vue"),
      },
      {
        name: "barcodes",
        path: "/barcodes",
        component: () => import("@/views/BarcodeReaderPage.vue"),
      },
      {
        name: "mensagens-worten",
        path: "/mensagens-worten",
        component: () => import("@/views/WortenMessages.vue"),
      }
    ],
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "Autenticar",
    },
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter() {
      localStorage.clear();
      return { name: "login" };
    },
  },
  {
    path: "/print/process/:id",
    name: "print-process",
    component: () => import("@/views/Print/PrintProcess.vue"),
  },
  {
    path: "/print/part/:id",
    name: "print-part",
    component: () => import("@/views/Print/PrintPart.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Set title
router.beforeEach((to, from, next) => {
  const title = to.meta.title;
  const titleFromParams = to.params.pageTitle;

  if (title) {
    document.title = `${title} | Infoservice`;
  } else {
    document.title = "Infoservice";
  }

  if (titleFromParams) {
    document.title = `${titleFromParams} - ${document.title}`;
  }

  next();
});

// Check if user is logged in
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("token") !== null;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ path: "/login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
